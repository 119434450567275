<template>
  <div class="d-flex flex-column flex-root">
    <div
        class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
        :class="{
        'login-signin-on': this.state === 'signin',
        'login-signup-on': this.state === 'signup',
        'login-forgot-on': this.state === 'forgot'
      }"
        id="kt_login"
    >
      <!--begin::Aside-->
      <div
          class="login-aside d-flex flex-column flex-row-auto"
          style="background-color: #F2C98A;"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-20 pt-15">
          <a href="#" class="text-center mb-10">
            <img
                src="media/logos/Logo-white.svg"
                class="w-60px"
                alt=""
            />
          </a>
          <h3
              class="font-weight-bolder text-center font-size-h4 font-size-h1-lg"
              style="color: #986923;"
          >
            Discover Amazing Metronic <br/>with great build tools
          </h3>
        </div>
        <div
            class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
            :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
          class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
                class="form"
                novalidate="novalidate"
                id="kt_login_signin_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                    class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  {{ $t('label.welcome_to_building_maintenance') }}
                </h3>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark">{{ $t('label.username') }}</label>
                <div
                    id="example-input-group-1"
                    label=""
                    label-for="example-input-1"
                >
                  <input
                      class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                      type="text"
                      name="username"
                      ref="username"
                      v-model="form.username"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5">{{ $t('label.password') }}</label>
                  <a
                      class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                      id="kt_login_forgot"
                      @click="showForm('forgot')"
                  >
                    {{ $t('label.forgot_password') }} ?
                  </a>
                </div>
                <div
                    id="example-input-group-2"
                    label=""
                    label-for="example-input-2"
                >
                  <input
                      class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                      type="password"
                      name="password"
                      ref="password"
                      v-model="form.password"
                      autocomplete="off"
                  />
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                    ref="kt_login_signin_submit"
                    class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                >
                  {{ $t('btn.sign_in') }}
                </button>
              </div>
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form
                class="form"
                novalidate="novalidate"
                id="kt_login_forgot_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                    class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  {{ $t('label.forgotten_password') }} ?
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  {{ $t('help.enter_your_person_id_to_reset_your_password') }}
                </p>

                <div v-if="resetSuccess" class="alert alert-custom alert-light-success" role="alert">
                  <div class="alert-text">{{ resetMsg }}</div>
                </div>
              </div>
              <div class="form-group">
                <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                    type="text"
                    placeholder="Person ID"
                    name="personId"
                    v-model="form.personId"
                />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                    ref="kt_login_forgot_submit"
                    class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                >
                  {{ $t('btn.submit') }}
                </button>
                <button
                    type="button"
                    id="kt_login_forgot_cancel"
                    class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                    @click="showForm('signin')"
                >
                  {{ $t('btn.cancel') }}
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
        </div>
        <!--begin::Content footer-->
        <div class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0">
          <a href="#" class="text-primary font-weight-bolder font-size-h5">{{ $t('label.terms') }}</a>
          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5">{{ $t('label.plans') }}</a>
          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5">{{ $t('label.contact_us') }}</a>
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import {mapGetters, mapState} from "vuex";
import {LOGIN, FORGOT_PASSWORD, LOGOUT} from "@/store/core/auth.module";
import Swal from "sweetalert2";
import i18n from '@/core/plugins/vue-i18n';

export default {
  name: 'login-1',
  data() {
    return {
      state: 'signin',
      // Remove this dummy login info
      form: {
        username: '',
        password: '',
        personId: ''
      },
      resetSuccess: false,
      resetMsg: ''
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(['currentUser']),

    backgroundImage() {
      return (
          process.env.BASE_URL + 'media/svg/illustrations/login-visual-1.svg'
      );
    }
  },
  mounted() {
    const signin_form = KTUtil.getById('kt_login_signin_form');
    const forgot_form = KTUtil.getById('kt_login_forgot_form');

    this.fv = formValidation(signin_form, {
      fields: {
        username: {
          validators: {
            notEmpty: {
              message: i18n.t('description.username_is_required')
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: i18n.t('description.password_is_required')
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv2 = formValidation(forgot_form, {
      fields: {
        personId: {
          validators: {
            notEmpty: {
              message: 'Person ID is required'
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv.on('core.form.valid', () => {
      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs['kt_login_signin_submit'];
      submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');

      // dummy delay
      setTimeout(() => {
        // send login request
        this.$store
            .dispatch(LOGIN, this.form)
            // go to which page after successfully login
            .then(() => this.$router.push({name: 'dashboard'}))
            .catch(() => {});

        submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
      }, 2000);
    });

    this.fv2.on('core.form.valid', () => {
      this.resetSuccess = false;
      // set spinner to submit button
      const submitButton = this.$refs['kt_login_forgot_submit'];
      submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');

      // dummy delay
      setTimeout(() => {
        // send login request
        this.$store
            .dispatch(FORGOT_PASSWORD, this.form)
            // go to which page after successfully login
            .then((response) => {
              this.resetSuccess = true;
              this.resetMsg = response.data.message;
            })
            .catch(() => {
              this.resetSuccess = false;
            });

        submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
      }, 2000);
    });

    this.fv.on('core.form.invalid', () => {
      Swal.fire({
        title: '',
        text: 'Please, provide correct data!',
        icon: 'error',
        confirmButtonClass: 'btn btn-secondary',
        heightAuto: false
      });
    });

    this.fv2.on('core.form.invalid', () => {
      Swal.fire({
        title: '',
        text: 'Please, provide correct data!',
        icon: 'error',
        confirmButtonClass: 'btn btn-secondary',
        heightAuto: false
      });
    });
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = 'kt_login_' + form + '_form';
      KTUtil.animateClass(
          KTUtil.getById(form_name),
          'animate__animated animate__backInUp'
      );
    }
  }
};
</script>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";

#kt_login {
  min-height: 100vh;
}
</style>